import React, { useContext, useEffect } from "react";
import { IconCurrencyDollar, IconCurrencyEuro } from "@tabler/icons-react";
import { useState } from "react";
import { useControllerState } from "../../../../coveo/utils/useControllerState";
import { RegionContext } from "./RegionProvider";
import {
  ControllerType,
  useControllerContext,
} from "./EngineAndControllerProvider";
import { useSearchMade } from "../hooks/useSearchMade";
import { Input } from "@/components/ui/input";
import { DualRangeSlider } from "../../../../../components/slider/DualRangeSlider";

const MIN_DEFAULT_VALUE = 0;
const MAX_DEFAULT_VALUE = 500_000;

const convertPriceStringToNumber = (value: string): number | undefined => {
  const parsedValue = Number(value.replace(/,/g, "").replace(/[^0-9.]/g, ""));

  if (!Number.isNaN(parsedValue)) {
    return parsedValue;
  }
};

export const PriceFilter: React.FC = () => {
  const region = useContext(RegionContext);

  const priceFilterController = useControllerContext(
    ControllerType.PriceFilter,
  );

  const priceFacetController = useControllerContext(ControllerType.PriceFacet);
  const priceFilterState = useControllerState(priceFilterController);
  const priceFacetState = useControllerState(priceFacetController);
  const searchMade = useSearchMade();

  const [min, setMin] = useState<number>(
    priceFilterState.range?.start ?? MIN_DEFAULT_VALUE,
  );

  const [max, setMax] = useState<number>(
    priceFilterState.range?.end ?? MAX_DEFAULT_VALUE,
  );

  const [highestPriceInResults, setHighestPriceInResults] = useState<number>(max);

  const [isDirty, setIsDirty] = useState(false);

  useEffect(() => {
    if (priceFacetState.values.length === 0 || searchMade) {
      return;
    }

    const highestPriceInResults =
      priceFacetState.values[priceFacetState.values.length - 1].end;

    setHighestPriceInResults(highestPriceInResults);
  }, [priceFacetState, searchMade]);

  useEffect(() => {
    const priceFilterRangeStart = priceFilterState.range?.start?.toString();

    if (priceFilterRangeStart) {
      convertPriceToNumberAndSetMin(priceFilterRangeStart);
    } else {
      setMin(MIN_DEFAULT_VALUE);
    }

    const priceFilterRangeEnd = priceFilterState.range?.end?.toString();

    if (priceFilterRangeEnd) {
      convertPriceToNumberAndSetMax(priceFilterRangeEnd);
    } else {
      setMax(highestPriceInResults || MAX_DEFAULT_VALUE);
    }
  }, [priceFilterState.range, highestPriceInResults]);

  const updateRange = () => {
    priceFilterController.setRange({ start: min, end: max });
    setIsDirty(false);
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      updateRange();
      e.currentTarget.blur();
    }
  };

  const convertPriceToNumberAndSetMin = (value: string) => {
    const newMin = convertPriceStringToNumber(value);
    if (newMin !== undefined) setMin(newMin);
  }

  const convertPriceToNumberAndSetMax = (value: string) => {
    const newMax = convertPriceStringToNumber(value);
    if (newMax !== undefined) setMax(newMax);
  }

  return (
    <div className="min-w-56">
      <span className="text-md-medium text-primary flex flex-row bg-white justify-between w-full py-2 px-1 border-0 border-b border-border-secondary border-solid mt-2 mb-4">
        Price
      </span>
      <div className="flex flex-col text-slate-500 opacity-100">
        <span className="text-sm-medium text-secondary mb-2">Min</span>
        <div className="relative">
          {region === "eu" ? (
            <IconCurrencyEuro className="absolute w-5 h-5 left-2.5 mt-[12px]" />
          ) : (
            <IconCurrencyDollar className="absolute w-5 h-5 left-2.5 mt-[12px]" />
          )}

          <Input
            className="pl-8"
            type="text"
            value={min.toLocaleString()}
            onChange={(e) => {
              convertPriceToNumberAndSetMin(e.target.value);
              setIsDirty(true);
            }}
            placeholder="Start"
            onBlur={() => {
              if (isDirty) updateRange();
            }}
            onKeyDown={handleKeyDown}
          />
        </div>

        <span className="text-sm-medium text-secondary mb-2">Max</span>
        <div className="flex flex-col text-slate-500 opacity-100 relative">
          {region === "eu" ? (
            <IconCurrencyEuro className="absolute w-5 h-5 left-2.5 mt-[12px]" />
          ) : (
            <IconCurrencyDollar className="absolute w-5 h-5 left-2.5 mt-[12px]" />
          )}
          <Input
            className="pl-8"
            type="text"
            value={max.toLocaleString()}
            onChange={(e) => {
              convertPriceToNumberAndSetMax(e.target.value)
              setIsDirty(true);
            }}
            placeholder="End"
            onBlur={() => {
              if (isDirty) updateRange();
            }}
            onKeyDown={handleKeyDown}
          />
        </div>

        <DualRangeSlider
          value={[min, max]}
          labelPosition="bottom"
          className="mb-8 mt-12"
          minStepsBetweenThumbs={0}
          min={0}
          max={highestPriceInResults}
          onValueCommit={updateRange}
          onValueChange={(value: number[]) => {
            convertPriceToNumberAndSetMin(value[0].toString());
            convertPriceToNumberAndSetMax(value[1].toString());
          }}
        />
      </div>
    </div>
  );
};
